<div class="container">
  <div class="chat-container">
    <div #messagesContainer class="messages-container">
      <ng-container *ngFor="let message of messages">
        <app-message [message]="message"></app-message>
      </ng-container>
    </div>
    <div *ngIf="responseLoading$ | async" class="loader-container">
      <app-loader></app-loader>
    </div>
    <div class="message-input-container">
      <app-message-input></app-message-input>
    </div>
  </div>
</div>
