import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientAiChatComponent } from './components/patient-ai-chat/patient-ai-chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageComponent } from './components/message/message.component';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    PatientAiChatComponent,
    MessageComponent,
    MessageInputComponent,
    LoaderComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, HttpClientModule],
})
export class ChatModule {}
