import { Component } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
})
export class MessageInputComponent {
  messageInput!: string;

  constructor(private chatService: ChatService) {}

  sendMessage(): void {
    if (this.messageInput) {
      let params = new HttpParams();
      params = params.append('messageInput', this.messageInput);

      this.chatService.addMessage(false, this.messageInput);
      this.messageInput = '';
      this.chatService.responseLoading(true);

      this.chatService.sendMessageToAssistant(params).subscribe((data: any) => {
        this.chatService.addMessage(true, data['response']);
        this.chatService.responseLoading(false);
      });
    }
  }
}
