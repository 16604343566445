import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessageInterface } from '../types/chat-message.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  private messages = new BehaviorSubject<ChatMessageInterface[]>([
    {
      username: 'ChatBot',
      userId: '12345',
      userAvatar: 'assets/pngs/assistantAvatar.png',
      assistantMessage: true,
      messageText: 'Wie kann ich Ihnen heute helfen?',
    },
  ]);

  private messageLoaderSubject = new BehaviorSubject<boolean>(false);

  getMessages(): Observable<ChatMessageInterface[]> {
    return this.messages.asObservable();
  }

  responseLoading(param: boolean): void {
    this.messageLoaderSubject.next(param);
  }

  isResponseLoading$(): Observable<boolean> {
    return this.messageLoaderSubject.asObservable();
  }

  sendPatientAnamnesis(params: HttpParams): void {
    this.http
      .post<any>('https://bot-api.hebronsoft.com/patient_info', params)
      .subscribe((data) => {});
  }

  sendMessageToAssistant(params: HttpParams): Observable<any> {
    return this.http.post<any>('https://bot-api.hebronsoft.com/', params);
  }

  addMessage(assistantMessage: boolean, messageText: string) {
    const newMessage: ChatMessageInterface = {
      username: assistantMessage ? 'ChatBot' : 'Patient',
      userId: '12345',
      userAvatar: assistantMessage
        ? 'assets/pngs/assistantAvatar.png'
        : 'assets/pngs/patientAvatar.png',
      assistantMessage: assistantMessage,
      messageText: messageText,
    };

    const updatedMessages = [...this.messages.value, newMessage];

    this.messages.next(updatedMessages);
  }
}
