import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientAiChatComponent } from './chat/components/patient-ai-chat/patient-ai-chat.component';
import { PatientAnamnesisComponent } from './pages/patient-anamnesis/patient-anamnesis.component';

const routes: Routes = [
  { path: '', component: PatientAnamnesisComponent },
  { path: 'patient-ai-chat', component: PatientAiChatComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
