<div
  [ngClass]="{
    'assistant-message-container': message.assistantMessage,
    'patient-message-container': !message.assistantMessage
  }"
>
  <div class="message-content">
    <img class="avatar" [src]="message.userAvatar" alt="userAvatar" />
    <p class="message-text" [innerHTML]="message.messageText" ></p>
  </div>
</div>
