import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ChatService } from 'src/app/chat/services/chat.service';

@Component({
  selector: 'app-patient-anamnesis',
  templateUrl: './patient-anamnesis.component.html',
  styleUrls: ['./patient-anamnesis.component.scss'],
})
export class PatientAnamnesisComponent implements OnInit {
  patientDataForm!: FormGroup;
  isFormValid = false;
  patientName: any = '';
  patientAnamnesis: any = '';
  data: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.initializePatientDataForm();
  }

  submitPatientData(): void {
    this.router.navigate(['/patient-ai-chat']);
    this.patientName = this.patientDataForm.value['patientName'];
    this.patientAnamnesis = this.patientDataForm.value['patientAnamnesis'];

    let params = new HttpParams();
    params = params.append('patientName', this.patientName);
    params = params.append('patientAnamnesis', this.patientAnamnesis);

    this.chatService.sendPatientAnamnesis(params);
  }

  private initializePatientDataForm(): void {
    this.patientDataForm = this.formBuilder.group({
      patientAnamnesis: ['', Validators.required],
      patientName: ['', Validators.required],
    });

    this.patientDataForm.valueChanges.subscribe(() => {
      this.isFormValid = this.patientDataForm.valid;
    });
  }
}
