<div class="container">
  <div class="input-field-container">
    <form [formGroup]="patientDataForm" (ngSubmit)="submitPatientData()">
      <input
        type="text"
        placeholder="Patienten benennen"
        class="patient-name-input"
        formControlName="patientName"
      />
      <textarea
        placeholder="Beschreiben Sie Ihren Patienten"
        class="patient-anamnesis-textarea"
        formControlName="patientAnamnesis"
        (keyup.enter)="submitPatientData()"
      ></textarea>

      <button [disabled]="!isFormValid" type="submit">
        <img
          [src]="
            isFormValid
              ? '/assets/svg/send-active.svg'
              : '/assets/svg/send-disabled.svg'
          "
          alt="send"
        />
      </button>
    </form>
  </div>
</div>
