import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { ChatMessageInterface } from '../../types/chat-message.interface';

@Component({
  selector: 'app-patient-ai-chat',
  templateUrl: './patient-ai-chat.component.html',
  styleUrls: ['./patient-ai-chat.component.scss'],
})
export class PatientAiChatComponent implements OnInit {
  @ViewChild('messagesContainer', { static: true })
  messagesContainer!: ElementRef;

  newMessageArray: any;
  messages: ChatMessageInterface[] = [];
  responseLoading$!: Observable<boolean>;

  constructor(private chatService: ChatService) {
    this.responseLoading$ = this.chatService.isResponseLoading$();
  }
  ngOnInit(): void {
    this.getMessages();
  }

  scrollToLastMessage(): void {
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    }
  }

  updateMessages(
    assistantMessage: boolean,
    messageArray: ChatMessageInterface[]
  ): void {
    this.messages = [];

    messageArray.forEach((message) => {
      const newMessage: ChatMessageInterface = {
        username: assistantMessage ? 'ChatBot' : 'Patient',
        userId: '12345',
        userAvatar: assistantMessage
          ? 'assets/pngs/assistantAvatar.png'
          : 'assets/pngs/patientAvatar.png',
        assistantMessage: assistantMessage,
        messageText: message.messageText,
      };

      this.messages.push(newMessage);
    });
  }

  private getMessages(): void {
    this.chatService.getMessages().subscribe((messages) => {
      this.messages = messages;
      setTimeout(() => {
        this.scrollToLastMessage();
      }, 100);
    });
  }
}
