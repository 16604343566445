<div class="message-input-container">
  <div class="input-wrapper">
    <textarea
      placeholder="Eine Nachricht schicken"
      class="message-area"
      [(ngModel)]="messageInput"
      (keyup.enter)="sendMessage()"
    ></textarea>
    <img
      (click)="sendMessage()"
      [src]="
        messageInput
          ? 'assets/svg/send-active.svg'
          : 'assets/svg/send-disabled.svg'
      "
      alt="send message"
    />
  </div>
</div>
